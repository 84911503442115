html {
  height: 100%;
}

body {
  height: 100%;
}

.main-page {
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100%; */
  background-color: lightblue;
  height: 100vh;
  /* text-align: center; */
  /* margin: auto; */
}

.content {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
}
