.vertical-title {
  display: block;
  writing-mode: vertical-rl;
  text-orientation: mixed; 
  transform: rotate(180deg);
  transform-origin: center center;
  /* padding-left: 10px;  */
  max-width: 150px; 
  white-space: nowrap;
  position: relative; 
}

.left-bottom  {
   vertical-align: bottom;
   text-align: left;
   max-width: 100px;
}

.wrap-vertical-title {
  white-space: normal;
  overflow-wrap: break-word;
  max-width: 30px; 
}

.department-list {
  margin-top: 8px;
}

.department {
  display: block;
  margin-top: 4px;
  padding: 4px;
  background-color: #f0f0f0;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-roles {
  margin-top: 27px;
}

/* .role-title {
  display: block;
  margin-top: 4px;
  padding: 4px;
  background-color: #f0f0f0;
  border-radius: 4px;
} */

.no-role-titles {
  color: #ff0000;
  margin-top: 5px;
}

.selected {
  background-color: rgb(119, 197, 88);
}

.card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.title-roles {
  margin-bottom: 8px;
}

.submit-button {
  margin-top: 20px;
}

.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
}

.checkbox-label {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
}

.checkbox-input {
  margin-right: 6px;
}

table {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  /* width: 100%; */
  /* min-height: 500px; */
}

td,
th {
  border: 1px solid #dddddd;
  padding: 6px;
  /* width: 200px; */
}

tr:nth-child(even) {
  /* background-color: #dddddd; */
}

.table-container {
  overflow: auto;
  min-height: 500px;
}

.freeze-first-column th:first-child,
.freeze-first-column td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
}
