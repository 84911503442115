.child-content {
  background-color: lightblue;
}

.sidebar {
  background-color: rgb(220, 224, 247);
  box-shadow: 1px 1px white;
  padding: 20px;
  height: 100vh;
}
