.container-streaming {
  /* width: 100%; */
  /* background-color: red; */
  display: flex;
}

.esquerda-sidebar {
  /* text-align: center; */
  display: inline-block;
  width: 25%;
  height: 100vh;
  top: 0;
}

.direita-principal {
  display: inline-block;
  width: 75%;
  height: 220px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  height: 50vh;
}

body{
  font-family: 'Montserrat';
  /* height: 100%; */
  /* margin: 0; */
}


/* .bg {
  background-image: url("../../components/assets/images/bg_grey.jpg");
  height: 100%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */