header {
  background-color: rgb(190, 227, 250);
  border-bottom: 1px solid #f6f6f6;
  padding: 0.5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.conteudo {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.meio {
  display: flex;
  width: 50%;
  text-align: center;
  align-items: center;
}

.logo {
  /* left: 0;
    margin-left: auto; */
  float: left;
  /* width: 300px; */
  width: 25%;
  height: 100%;

  text-align: center;
  left: 5px;
}

.auth-links {
  /* align-items: center; */
  text-align: center;
  width: 25%;
  margin: auto;
  /* margin-right: auto; */
  /* float: right; */
  /* left: 0; */
  /* right: 15px; */
}

nav {
  /* display: flex; */
  justify-content: space-between;

  /* align-items: center; */
  /* max-width: 1024px; */
  /* margin: 0 auto; */
}

.logo img {
  height: 50px;
  width: 170px;
  align-items: center;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 0 1rem;
}

.nav-links a {
  color: #777777;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.03em;
}

.nav-links a:hover {
  color: #2a73cc;
}

.button-sign-up,
.button-log-in {
  border: 1px solid #2a73cc;
  color: #2a73cc;
  background-color: #ffffff;
  padding: 0.6rem 1rem;
  font-size: 14px;
  font-weight: 500;
  /* margin-left: 1rem; */
  text-align: center;
  text-decoration: none;
  float: right;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button-sign-up:hover,
.button-log-in:hover {
  background-color: #2a73cc;
  color: #ffffff;
}

.site-page-header-ghost-wrapper {
  /* padding: 10px; */
  /* background-color: red; */
}
