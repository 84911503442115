/* .main-test-content {
    background-color: red;
} */

.main-test-content .main-div {
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.main-div .top-div {
    /* background-color: green; */
    width: 100%;
}
.main-div .middle-div {
    /* background-color: lightblue; */
    width: 100%;

}

.main-div .bottom-div {
    /* background-color: orange; */
    width: 100%;

}

.top-div,
.middle-div,
.bottom-div {
  padding: 10px;

}

.float-right-button {
    float: right;
    /* background-color: red; */
}

body {
    font-family: 'Montserrat';
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    height: 50vh;
}

