.navbar {
  background-color: rgb(210, 217, 255);
}

.twigg-logo {
  /* background-color: red; */
  /* padding: 3px; */
  height: 50px;
  width: 100%;
}
