.size img {
    height: 38px;
    width: 38px;
    cursor: pointer;
}

.general {
    clear: both;

}

.size {
    float: left;
    width: 15%;
}

.vertical {
    border-left: 6px solid black;
    height: 200px;
    position: absolute;
    left: 50%;
}

.formation_description {
    font-size: medium;
}

.main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.or-left {
    color: grey;
}

/* .or-right {

    float: right;
} */