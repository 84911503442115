.central {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.esquerda {
  flex: 1;
}

.direita {
  text-align: right;
  font-size: 13px;
  font-weight: bold;
  background-color: lightgrey;
  border-radius: 5px;
}
.direita span {
  margin: 5px;
}
