.book-svg {
    width: 15%;
}

.calendar-svg {
    width: 15%;
}

.team-svg {
    width: 15%;
}

