.form_leyout form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #1475cf;
  margin: 0 auto;
  /* height: 300px;
  width: 500px; */
  cursor: pointer;
  border-radius: 5px;
}

.uploaded-row {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #e9f0ff;
}

.upload-content {
  display: flex;
  align-items: center;
}
