/* .sidebar-content {
    height: 100vh;
    background-color: lightblue;
    display: flex;
    float: left;
    padding: 5%;
} */

aside {
  /* background-color: red; */
  height: 100vh;
}

.sidebar-content {
  height: 100vh;
  /* background-color: lightgoldenrodyellow; */
  /* width: 250px; */
  position: fixed;
  left: 0;
  overflow-y: auto;
  /* padding: 3%; */
}

.course-details {
  text-align: center;
}

.course-side-list:hover {
  /* background-color: yellow; */
  /* font-size: medium; */
  color: black;
  cursor: pointer;
}

.course-side-list {
  list-style: none;
}

.menu-icon {
  color: black;
}

.menu-icon:hover {
  color: orange;
  cursor: pointer;
}

/* sidebar.css */
.course-side-list.selected {
  background-color: #f0f0f0;
  position: relative;
}

.course-side-list.selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  width: 6px;
  height: 100%;
  background-color: #2a73cc; /* Change to the desired blue color */
}

/* sidebar.css */

.course-title {
  font-weight: bold;
  /* margin-left: 20px; */
  /* text-align: left; */
  color: black;
}

.course-title-description {
  color: black;
  margin-left: 20px;
}

.course-title-subtitle {
  /* margin-left: 20px; */
}
