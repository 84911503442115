.main {
  text-align: center;
}

.left {
  background-color: blue;
}

.right {
  background-color: lightblue;
}
