.custom li {
    list-style: none;
    margin: 1.2em 0;
    background-color: #a7c5df;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 1.2em 1.5em;

    svg {
        color: #6993b6;
        &:first-child {
            font-size: 1.2em;
            margin-right: 0.8em;
        }
    }

    p {
        flex: 1;
        font-size: 0.9rem;
        font-weight: 530;
    }

    .actions {
        justify-self: flex-end;
        cursor: pointer;

        .fa-spinner {
            font-size: 1.2em;
        }
    }
}
